import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  DocumentsDataService,
  PickupPreference,
  QuoteDataService,
  ServiceType,
  ShipmentOrderDataService,
  ShipmentOrderPackageDataService,
  TaskBlockType,
  TaskCategory,
  TaskChildRecords,
  TaskComponentName,
  TaskDetails,
  TaskInputType,
  TasksDataService,
  TaskState,
  TaskTemplateBlocks,
  UpdatePartsRequestParts,
} from '@tecex-api/data';
import isNil from 'lodash/isNil';
import zip from 'lodash/zip';
import { EMPTY, forkJoin, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, mapTo, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RePollService } from '../../../../../../projects/tecex/src/app/shared/services/re-poll.service';
import { BaseAddressService } from '../../../base-classes/base-address.service';
import { CONFIG_TOKEN } from '../../../config/config.token';
import { GlobalConfig } from '../../../config/global-config.interface';
import { AddressType } from '../../../enums/address-type.enum';
import { LengthUnit } from '../../../enums/length-unit.enum';
import { MessageChannelType } from '../../../enums/message-channel-type.enum';
import { MixpanelEvent } from '../../../enums/mixpanel-event.enum';
import { NoUpdateTaskTitles } from '../../../enums/task-no-update.enum';
import { WeightUnit } from '../../../enums/weight-unit.enum';
import { assertNever } from '../../../helpers/assert-never.helper';
import { FileHelper } from '../../../helpers/file.helper';
import { mapLengthUnit } from '../../../helpers/map-length-unit.helper';
import { mapShipmentOrderPackages } from '../../../helpers/map-shipment-order-packages.helper';
import { mapWeightUnitWithNull } from '../../../helpers/map-weight-unit.helper';
import { convertTimeString } from '../../../helpers/time.helper';
import { AddressCardAddressVM } from '../../../interfaces/address/address.vm';
import { ChargeableWeightDialogResult } from '../../../interfaces/chargeable-weight-dialog.vm';
import { InputDataVM } from '../../../interfaces/input-data.vm';
import { AuthService } from '../../../services/auth.service';
import { ErrorNotificationService } from '../../../services/error-notification.service';
import { MixpanelService } from '../../../services/mixpanel.service';
import { TeamMemberService } from '../../../services/team-member.service';
import { UserDefaultsService } from '../../../services/user-defaults.service';
import { ADDRESS_SERVICE } from '../../../tokens/address-service.token';
import { BaseCountryService } from '../../country/base-classes/base-country.service';
import { COUNTRY_SERVICE_TOKEN } from '../../country/tokens/country-service.token';
import { LoadingIndicatorService } from '../../loading-indicator/services/loading-indicator.service';
import { MessageButtonUserVM } from '../../message-button/user.vm';
import { TasksMessageDialogService } from '../../message-dialog/services/tasks-message-dialog.service';
import { TeamMemberListType } from '../../message-thread/enums/team-member-list-type.enum';
import { PackageService } from '../../packages/package.service';
import { AirWaybillDialogService } from '../components/air-waybill/air-waybill-dialog.service';
import { BeneficialOwnerDialogService } from '../components/beneficial-owner/beneficial-owner-dialog.service';
import { NOT_PROVIDED_CATEGORY, OTHER_CATEGORY } from '../components/part-sub-category-table-block/part-sub-category-table-block.component';
import { PreInspectionDialogService } from '../components/pre-inspection/pre-inspection-dialog.service';
import { TasksDialogService } from '../components/tasks-dialog/tasks-dialog.service';
import { PreInspectionFacilitator } from '../enums/pre-inspection-facilitator.enum';
import { getISODateStringWithCurrentTimezone } from '../helpers/get-isodate-string-with-tz';
import { mapAirWaybillResponse } from '../helpers/map-air-waybill-response.helper';
import { mapTaskShipmentOrder } from '../helpers/map-task-shipment-order.helper';
import { mapUpdatePackagePayload } from '../helpers/map-update-package-payload.helper';
import { BatteryTableBlockResult } from '../types/battery-table-block-result.type';
import { BlockType } from '../types/block-type.enum';
import { Block } from '../types/block.type';
import { DatepickerBlockResult } from '../types/datepicker-block-result.interface';
import { InputType } from '../types/input-type.enum';
import { ResultHandler } from '../types/result-handler.type';
import { TaskType } from '../types/task-type.enum';
import { TaskVM } from '../types/task.vm';
import { SerialNumbersDialogService } from '../components/serial-numbers/serial-numbers-dialog.service';
import { SourceApp } from '../../../enums/source-app.enum';
import { ChildRecordsDialogService } from '../components/child-records/child-records-dialog.service';
import { ObjectTypeEnum } from '../../announcment/components/account-change-dialog/object-type.enum';
import { AnnouncmentService } from '../../announcment/services/announcment.service';

interface MappedBlock {
  data: Block;
  resultHandler?: ResultHandler;
}

class TaskError extends Error {}

@Injectable()
export class TasksService {
  constructor(
    @Inject(CONFIG_TOKEN) private readonly config: GlobalConfig,
    @Inject(ADDRESS_SERVICE) private readonly addressService: BaseAddressService,
    private readonly authService: AuthService,
    private readonly tasksDataService: TasksDataService,
    private readonly shipmentOrderDataService: ShipmentOrderDataService,
    private readonly loadingIndicatorService: LoadingIndicatorService,
    private readonly tasksDialogService: TasksDialogService,
    private readonly quoteDataService: QuoteDataService,
    private readonly packageService: PackageService,
    private readonly documentsDataService: DocumentsDataService,
    private readonly errorNotificationService: ErrorNotificationService,
    private readonly preInspectionDialogService: PreInspectionDialogService,
    private readonly shipmentOrderPackageDataService: ShipmentOrderPackageDataService,
    private readonly airWaybillDialogservice: AirWaybillDialogService,
    private readonly userDefaultsService: UserDefaultsService,
    private readonly teamMemberService: TeamMemberService,
    private readonly beneficialOwnerDialogService: BeneficialOwnerDialogService,
    private readonly tasksMessageDialogService: TasksMessageDialogService,
    private readonly rePollService: RePollService,
    private readonly mixpanelService: MixpanelService,
    private readonly router: Router,
    private readonly serialNumbersDialogService: SerialNumbersDialogService,
    private readonly childRecordsDialogService: ChildRecordsDialogService,
    private readonly announcmentService: AnnouncmentService,
    @Inject(COUNTRY_SERVICE_TOKEN) private readonly countryService: BaseCountryService
  ) {}

  /**
   * Open task dialog
   *
   * @param id - A task's id
   * @param showShipmentOrderBox - To show or hide the belonging shipment order data
   */
  public open$(id: string, showShipmentOrderBox = true): Observable<boolean> {
    this.loadingIndicatorService.open();

    return this.authService.getUser$().pipe(
      switchMap((user) =>
        this.tasksDataService
          .getTask({
            AccessToken: user.accessToken,
            TaskID: id,
          })
          .pipe(
            switchMap((taskDetails) => {
              const selectedAccountId = sessionStorage.getItem('SelectedAccount');
              if (selectedAccountId !== taskDetails.task.Shipment_Order__r.Account__c) {
                this.announcmentService.accountChangeModal(ObjectTypeEnum.TASK, taskDetails.task.Shipment_Order__r.Account__c);
                return EMPTY;
              }
              return taskDetails.task.Task_Category__c === TaskCategory.ONBOARDING
                ? this.teamMemberService
                    .getDefaultTeamMember$(taskDetails.task.Shipment_Order__c, TeamMemberListType.Onboarding)
                    .pipe(map((defaultTeamMember) => ({ taskDetails, defaultTeamMember })))
                : this.teamMemberService
                    .getTeamMemberRole$(taskDetails.task.Shipment_Order__c, TeamMemberListType.ShipmentOrder)
                    .pipe(map((defaultTeamMember) => ({ taskDetails, defaultTeamMember: defaultTeamMember[0] })));
            })
          )
      ),
      map((response) => {
        this.mixpanelService.track(MixpanelEvent.UserAction, {
          screen_name_base: this.router.url.includes('dashboard') ? '/dashboard#task' : '/shipments-list/shipment-order',
          button_name: 'Open task',
          task_title: response.taskDetails.template.taskTitle,
          master_task_id: response.taskDetails.task.Master_Task__c,
          client_assigned_to: response.taskDetails.task.Client_Assigned_To__c,
        });
        const taskType = this.getTaskType(response.taskDetails);
        return {
          ...response,
          taskType,
          onMsgClickHandler: this.createOnMsgClickHandler(response, taskType),
        };
      }),
      switchMap((response) => {
        switch (response.taskType) {
          case TaskType.Blocks: {
            return this.openBlocksTask$(response, showShipmentOrderBox);
          }
          case TaskType.PreInspection: {
            return this.openPreInspectionTask$(response, showShipmentOrderBox);
          }
          case TaskType.AirWaybill: {
            return this.openAirWaybillTask$(response, showShipmentOrderBox);
          }
          case TaskType.BeneficialOwner: {
            return this.openBeneficialOwnerTask$(response, showShipmentOrderBox);
          }
          case TaskType.SerialNumbers: {
            return this.openSerialNumberTask$(response, showShipmentOrderBox);
          }
          case TaskType.ChildRecords: {
            return this.openChildRecordsTask$(response, showShipmentOrderBox);
          }
          default: {
            return assertNever(response.taskType);
          }
        }
      }),
      catchError((error) => {
        this.loadingIndicatorService.dispose();
        const errorMessageTranslationKey = error instanceof TaskError ? error.message : 'ERROR.FAILED_TO_LOAD_TASK';
        this.errorNotificationService.notifyAboutError(error, errorMessageTranslationKey);

        return EMPTY;
      })
    );
  }

  public mapResponse$({ taskDetails }: { taskDetails: TaskDetails }): Observable<{ task: TaskVM; resultHandlers: ResultHandler[] }> {
    const blocks$: Observable<MappedBlock[]> =
      taskDetails.template.blocks.length === 0
        ? of([])
        : forkJoin(taskDetails.template.blocks.map((block) => this.mapBlock$(block, taskDetails)).filter((block$) => !isNil(block$)));

    return blocks$.pipe(
      map((blocks) => ({
        task: {
          id: taskDetails.task.Id,
          title: taskDetails.template.taskTitle,
          description: taskDetails.template.taskDescription,
          shipmentOrder: mapTaskShipmentOrder(taskDetails),
          blocks: blocks.map(({ data }) => data),
          buttonLabel: taskDetails.template.btnLabel,
          hasSubmitButton: blocks.length > 0,
          messageThread:
            !isNil(taskDetails.FeedsOnTask) && taskDetails.FeedsOnTask.length > 0
              ? {
                  subject: taskDetails.template.taskTitle,
                  participants:
                    taskDetails.Participants?.map((participant) => ({
                      firstName: participant.Firstname,
                      lastName: participant.Lastname,
                      pictureUrl: participant.fullphotoUrl,
                    })) || [],
                }
              : undefined,
          isInactive: taskDetails.task.Inactive__c,
        },
        resultHandlers: blocks.map(({ resultHandler }) => resultHandler),
      }))
    );
  }

  /**
   * Handler for submitting a task
   *
   * @param taskDetails
   * @param task
   * @param results
   * @param resultHandlers
   */
  // eslint-disable-next-line sonarjs/cognitive-complexity
  public handleSubmit$({
    taskDetails,
    task,
    results,
    resultHandlers,
  }: {
    taskDetails: TaskDetails;
    task: TaskVM;
    results: any[];
    resultHandlers: ResultHandler[];
  }): Observable<boolean> {
    if (!results) {
      this.mixpanelCloseTask(taskDetails);
      return of(false);
    }

    this.loadingIndicatorService.open();

    const handledResults$ =
      results.length === 0
        ? of([])
        : forkJoin(
            zip(results, resultHandlers).map(([result, resultHandler]) => {
              if (!resultHandler) {
                return of(result);
              }

              // Adding a condition, if image is null then won't call result-handler
              return result ? resultHandler(result) : [null];
            })
          );

    // Adding the value of input and button
    for (const i of taskDetails.template.blocks) {
      if (i.sfFieldName === 'Reason_for_no_PO__c') {
        i.value = results[1];
      } else if (i.sfFieldName === 'Reason_for_no_PO_other__c') {
        i.value = results[2];
      }
    }

    return handledResults$.pipe(
      withLatestFrom(this.authService.getUser$()),
      switchMap(([handledResults, user]) => {
        if (!Object.values(NoUpdateTaskTitles).toString().includes(taskDetails.template.taskTitle)) {
          return this.rePollService.updateTaskRetry$({
            accessToken: user.accessToken,
            taskId: task.id,
            taskData: {
              ...taskDetails.template,
              blocks: zip(taskDetails.template.blocks, handledResults).map(([block, result]) => ({
                ...block,
                value: result,
              })),
            },
          });
        }
        return of(true);
      }),
      map((resp) => {
        this.mixpanelSubmitTask(taskDetails, true);
      }),
      mapTo(true),
      catchError((error) => {
        this.mixpanelSubmitTask(taskDetails, false);
        if (error instanceof TaskError) {
          return throwError(error);
        }

        return throwError(new TaskError('ERROR.FAILED_TO_SAVE_TASK'));
      })
    );
  }

  /**
   * Get the task's type from its details
   *
   * @param taskDetails
   * @private
   */
  private getTaskType(taskDetails: TaskDetails): TaskType {
    const hasPreInspectionBlock = taskDetails.template.blocks?.some(
      (block) => block.cmpName === TaskComponentName.PRE_INSPECTION_RESPONSIBILITY__INSTRUCTION
    );
    if (hasPreInspectionBlock) {
      return TaskType.PreInspection;
    }

    const hasAirWayBillBlock = taskDetails.template.blocks?.some(
      (block) =>
        block.cmpName === TaskComponentName.CARRIER_CHOICE_AND_AWB_INSTRUCTION__NO_NOTIFY_PARTY ||
        block.cmpName === TaskComponentName.CARRIER_CHOICE_AND_AWB_INSTRUCTION__WITH_NOTIFY_PARTY
    );
    if (hasAirWayBillBlock) {
      return TaskType.AirWaybill;
    }

    const hasBeneficialOwnerBlock = taskDetails.template.blocks?.some((block) => block.cmpName === TaskComponentName.NL_PRODUCT_BO);
    if (hasBeneficialOwnerBlock) {
      return TaskType.BeneficialOwner;
    }

    const hasSerialNumberBlock = taskDetails.template.blocks?.some((block) => block.cmpName === TaskComponentName.ENTER_PART_SERIAL_NUMBER);
    if (hasSerialNumberBlock) {
      return TaskType.SerialNumbers;
    }

    const hasChildRecordsBlock = taskDetails.template.blocks?.some((block) => block.cmpName === TaskComponentName.DISPLAY_CHILD_RECORDS);
    if (hasChildRecordsBlock) {
      return TaskType.ChildRecords;
    }

    return TaskType.Blocks;
  }

  /**
   * Open a Block type task dialog
   *
   * @param response
   * @param showShipmentOrderBox
   * @private
   */
  private openBlocksTask$(
    response: { taskDetails: TaskDetails; defaultTeamMember: MessageButtonUserVM; onMsgClickHandler: () => void },
    showShipmentOrderBox: boolean
  ): Observable<boolean> {
    return this.mapResponse$(response).pipe(
      map((result) => ({ ...result, taskDetails: response.taskDetails })),
      finalize(() => this.loadingIndicatorService.dispose()),
      switchMap(({ taskDetails, task, resultHandlers }) => {
        task.master_task_id = taskDetails.task.Master_Task__c;
        const dialogRef = this.tasksDialogService.open({
          task,
          showShipmentOrderBox,
          defaultTeamMember: response.defaultTeamMember,
          onMsgClickHandler: response.onMsgClickHandler,
        });

        this.openMessageDeeplink(response.onMsgClickHandler);

        return dialogRef.afterClosed$().pipe(map((results) => ({ taskDetails, task, results, resultHandlers })));
      }),
      switchMap((data) => {
        return this.handleSubmit$(data);
      })
    );
  }

  private openChildRecordsTask$(
    {
      taskDetails,
      defaultTeamMember,
      onMsgClickHandler,
    }: {
      taskDetails: TaskDetails;
      defaultTeamMember: MessageButtonUserVM;
      onMsgClickHandler: () => void;
    },
    showShipmentOrderBox: boolean
  ): Observable<boolean> {
    return this.authService.getUser$().pipe(
      switchMap((user) => {
        return this.tasksDataService
          .getChildRecords({
            AccessToken: user.accessToken,
            AppName: SourceApp.TecEx,
            TaskID: taskDetails.task.Id,
          })
          .pipe(
            tap(() => this.loadingIndicatorService.dispose()),
            switchMap((response: TaskChildRecords) => {
              if (response.records.length === 0) {
                this.errorNotificationService.notifyAboutError('No Child Records', 'ERROR.NO_CHILD_RECORDS');
                return EMPTY;
              }
              return this.childRecordsDialogService
                .open({
                  response,
                  id: taskDetails.SOInfo[0].Id,
                  title: taskDetails.template.taskTitle,
                  description: taskDetails.template.taskDescription,
                  shipmentOrder: mapTaskShipmentOrder(taskDetails),
                  showShipmentOrderBox,
                  isInactive: taskDetails.task.Inactive__c,
                  defaultTeamMember: defaultTeamMember,
                  onMsgClickHandler: onMsgClickHandler,
                })
                .afterClosed$()
                .pipe(
                  switchMap((dialogSuccess) => {
                    if (!dialogSuccess) {
                      this.mixpanelCloseTask(taskDetails);
                      this.loadingIndicatorService.dispose();
                      return EMPTY;
                    }
                    return this.loadingIndicatorService.withFullscreen$(
                      this.rePollService
                        .updateTaskRetry$({
                          accessToken: user.accessToken,
                          taskId: taskDetails.task.Id,
                          taskData: {
                            ...taskDetails.template,
                            blocks: taskDetails.template.blocks,
                          },
                        })
                        .pipe(finalize(() => this.loadingIndicatorService.dispose()))
                    );
                  }),
                  map(() => this.mixpanelSubmitTask(taskDetails, true)),
                  mapTo(true),
                  catchError(() => {
                    this.mixpanelSubmitTask(taskDetails, false);
                    return throwError(new TaskError('ERROR.FAILED_TO_SAVE_TASK'));
                  })
                );
            })
          );
      })
    );
  }

  /**
   * Open a PreInspection type task dialog
   *
   * @param taskDetails
   * @param defaultTeamMember
   * @param onMsgClickHandler
   * @param showShipmentOrderBox
   * @private
   */
  private openPreInspectionTask$(
    {
      taskDetails,
      defaultTeamMember,
      onMsgClickHandler,
    }: {
      taskDetails: TaskDetails;
      defaultTeamMember: MessageButtonUserVM;
      onMsgClickHandler: () => void;
    },
    showShipmentOrderBox: boolean
  ): Observable<boolean> {
    this.loadingIndicatorService.dispose();
    const dialogRef = this.preInspectionDialogService.open({
      id: taskDetails.task.Id,
      title: taskDetails.template.taskTitle,
      description: taskDetails.template.taskDescription,
      defaultTeamMember,
      shipmentOrder: mapTaskShipmentOrder(taskDetails),
      showShipmentOrderBox,
      isInactive: taskDetails.task.Inactive__c,
      onMsgClickHandler,
    });
    this.openMessageDeeplink(onMsgClickHandler);
    return dialogRef.afterClosed$().pipe(
      withLatestFrom(this.authService.getUser$()),
      switchMap(([result, user]) => {
        if (isNil(result)) {
          this.mixpanelCloseTask(taskDetails);
          return of(false);
        }

        this.loadingIndicatorService.open();

        return this.rePollService
          .updateTaskRetry$({
            accessToken: user.accessToken,
            taskId: taskDetails.task.Id,
            taskData: {
              btnValue: result === PreInspectionFacilitator.Tecex ? TaskState.UNDER_REVIEW : TaskState.RESOLVED,
              btnObject: 'Task',
              // tslint:disable-next-line: no-null-keyword
              btnLabel: null,
              btnField: 'State__c',
              btnAction: 'Yes',
              blocks: [],
            },
          })
          .pipe(
            map(() => this.mixpanelSubmitTask(taskDetails, true)),
            finalize(() => this.loadingIndicatorService.dispose()),
            mapTo(true),
            catchError(() => {
              this.mixpanelSubmitTask(taskDetails, false);
              return throwError(new TaskError('ERROR.FAILED_TO_SAVE_TASK'));
            })
          );
      })
    );
  }

  /**
   * Open a AirWaybill type task dialog
   *
   * @param taskDetails
   * @param defaultTeamMember
   * @param onMsgClickHandler
   * @param showShipmentOrderBox
   * @private
   */
  private openAirWaybillTask$(
    {
      taskDetails,
      defaultTeamMember,
      onMsgClickHandler,
    }: {
      taskDetails: TaskDetails;
      defaultTeamMember: MessageButtonUserVM;
      onMsgClickHandler: () => void;
    },
    showShipmentOrderBox: boolean
  ): Observable<boolean> {
    this.loadingIndicatorService.dispose();

    const airWaybillBlock = taskDetails.template.blocks.find(
      (block) =>
        block.cmpName === TaskComponentName.CARRIER_CHOICE_AND_AWB_INSTRUCTION__NO_NOTIFY_PARTY ||
        block.cmpName === TaskComponentName.CARRIER_CHOICE_AND_AWB_INSTRUCTION__WITH_NOTIFY_PARTY
    );

    const dialogRef = this.airWaybillDialogservice.open(
      mapAirWaybillResponse(taskDetails, defaultTeamMember, showShipmentOrderBox, onMsgClickHandler)
    );

    this.openMessageDeeplink(onMsgClickHandler);

    return dialogRef.afterClosed$().pipe(
      withLatestFrom(this.authService.getUser$()),
      switchMap(([result, user]) => {
        if (isNil(result)) {
          this.mixpanelCloseTask(taskDetails);
          return of(false);
        }
        this.loadingIndicatorService.open();

        return FileHelper.readFileAsBase64$(result.file).pipe(
          switchMap((filebody) =>
            this.documentsDataService.attachDocuments({
              Accesstoken: user.accessToken,
              RecordID: airWaybillBlock.sourceId,
              Atts: [
                {
                  filename: result.file.name,
                  filebody,
                },
              ],
            })
          ),
          switchMap(() =>
            this.rePollService.updateTaskRetry$({
              accessToken: user.accessToken,
              taskId: taskDetails.task.Id,
              taskData: {
                btnValue: TaskState.UNDER_REVIEW,
                btnObject: 'Task',
                // tslint:disable-next-line: no-null-keyword
                btnLabel: null,
                btnField: 'State__c',
                btnAction: 'Yes',
                blocks: [],
              },
            })
          ),
          map(() => this.mixpanelSubmitTask(taskDetails, true)),
          finalize(() => this.loadingIndicatorService.dispose()),
          mapTo(true),
          catchError(() => {
            this.mixpanelSubmitTask(taskDetails, false);
            return throwError(new TaskError('ERROR.FAILED_TO_SAVE_TASK'));
          })
        );
      })
    );
  }

  private openBeneficialOwnerTask$(
    {
      taskDetails,
      defaultTeamMember,
      onMsgClickHandler,
    }: {
      taskDetails: TaskDetails;
      defaultTeamMember: MessageButtonUserVM;
      onMsgClickHandler: () => void;
    },
    showShipmentOrderBox: boolean
  ): Observable<boolean> {
    return this.authService.getUser$().pipe(
      switchMap((user) =>
        forkJoin([
          this.countryService.getAllCountries$(),
          this.rePollService.pollSoDetails$({
            accessToken: user.accessToken,
            accountId: user.accountId,
            contactId: user.contactId,
            shipmentOrderId: taskDetails.SOInfo[0]?.ShipmentOrderID,
          }),
        ]).pipe(
          tap(() => this.loadingIndicatorService.dispose()),
          switchMap(([countries, shipmentOrderDetails]) =>
            this.beneficialOwnerDialogService
              .open({
                title: taskDetails.template.taskTitle,
                description: taskDetails.template.taskDescription,
                defaultTeamMember,
                shipmentOrder: mapTaskShipmentOrder(taskDetails),
                showShipmentOrderBox,
                isInactive: taskDetails.task.Inactive__c,
                countries,
                shipmentOrderCompanyName: shipmentOrderDetails.Account__r?.Name,
                onMsgClickHandler,
              })
              .afterClosed$()
          ),
          switchMap((dialogSuccess) => {
            if (!dialogSuccess) {
              this.mixpanelCloseTask(taskDetails);
              return EMPTY;
            }
            return this.loadingIndicatorService.withFullscreen$(
              this.rePollService.updateTaskRetry$({
                accessToken: user.accessToken,
                taskId: taskDetails.task.Id,
                taskData: {
                  btnValue: TaskState.UNDER_REVIEW,
                  btnObject: 'Task',
                  // tslint:disable-next-line: no-null-keyword
                  btnLabel: null,
                  btnField: 'State__c',
                  btnAction: 'Yes',
                  blocks: [],
                },
              })
            );
          }),
          map(() => this.mixpanelSubmitTask(taskDetails, true)),
          mapTo(true),
          catchError(() => {
            this.mixpanelSubmitTask(taskDetails, false);
            return throwError(new TaskError('ERROR.FAILED_TO_SAVE_TASK'));
          })
        )
      )
    );
  }

  private openSerialNumberTask$(
    {
      taskDetails,
      defaultTeamMember,
      onMsgClickHandler,
    }: {
      taskDetails: TaskDetails;
      defaultTeamMember: MessageButtonUserVM;
      onMsgClickHandler: () => void;
    },
    showShipmentOrderBox: boolean
  ): Observable<boolean> {
    return this.authService.getUser$().pipe(
      switchMap((user) => {
        return this.shipmentOrderDataService
          .getShipmentOrderRelations({
            Accesstoken: user.accessToken,
            AccountID: user.accountId,
            SOID: taskDetails.SOInfo[0].ShipmentOrderID,
          })
          .pipe(
            tap(() => this.loadingIndicatorService.dispose()),
            switchMap((response) => {
              const parts = response.Parts.map((part) => {
                return {
                  name: part.PartNumber,
                  serialNumber: '',
                  id: part.Id,
                  description: part.PartDescription,
                  quantity: part.Quantity,
                  unitPrice: part.UnitPrice,
                };
              });
              return this.serialNumbersDialogService
                .open({
                  parts,
                  id: taskDetails.SOInfo[0].Id,
                  title: taskDetails.template.taskTitle,
                  description: taskDetails.template.taskDescription,
                  defaultTeamMember,
                  shipmentOrder: mapTaskShipmentOrder(taskDetails),
                  showShipmentOrderBox,
                  isInactive: taskDetails.task.Inactive__c,
                  onMsgClickHandler,
                })
                .afterClosed$()
                .pipe(
                  switchMap((dialogSuccess) => {
                    if (!dialogSuccess) {
                      this.mixpanelCloseTask(taskDetails);
                      this.loadingIndicatorService.dispose();
                      return EMPTY;
                    }
                    return this.loadingIndicatorService.withFullscreen$(
                      this.rePollService
                        .updateTaskRetry$({
                          accessToken: user.accessToken,
                          taskId: taskDetails.task.Id,
                          taskData: {
                            btnValue: TaskState.UNDER_REVIEW,
                            btnObject: 'Task',
                            // tslint:disable-next-line: no-null-keyword
                            btnLabel: null,
                            btnField: 'State__c',
                            btnAction: 'Yes',
                            blocks: [],
                          },
                        })
                        .pipe(finalize(() => this.loadingIndicatorService.dispose()))
                    );
                  }),
                  map(() => this.mixpanelSubmitTask(taskDetails, true)),
                  mapTo(true),
                  catchError(() => {
                    this.mixpanelSubmitTask(taskDetails, false);
                    return throwError(new TaskError('ERROR.FAILED_TO_SAVE_TASK'));
                  })
                );
            })
          );
      })
    );
  }

  /**
   * Map Block type task data
   *
   * @param block
   * @param taskDetails
   * @private
   */
  private mapBlock$(block: TaskTemplateBlocks, taskDetails: TaskDetails): Observable<MappedBlock> {
    switch (block.blockType) {
      case TaskBlockType.INSTRUCTION: {
        return of({
          data: {
            type: BlockType.Instruction,
            payload: {
              displayNumber: block.displayNumber,
              text: block.blockDescription,
            },
          },
        });
      }
      case TaskBlockType.INPUT: {
        return this.mapInputBlock$(block);
      }
      case TaskBlockType.COMPONENT: {
        return this.mapComponentBlock$(block, taskDetails);
      }
    }
  }

  /**
   * Map Input type task block data
   *
   * @param block
   * @private
   */
  // tslint:disable-next-line: cyclomatic-complexity
  // eslint-disable-next-line sonarjs/cognitive-complexity
  private mapInputBlock$(block: TaskTemplateBlocks): Observable<MappedBlock> {
    switch (block.inputType) {
      case TaskInputType.STRING: {
        return of({
          data: {
            type: BlockType.Input,
            payload: {
              description: block.blockDescription,
              displayNumber: block.displayNumber,
              label: block.inputHeading,
              type: InputType.LongText,
              hint: block.inputHint,
            },
          },
        });
      }
      case TaskInputType.RADIO: {
        return of({
          data: {
            type: BlockType.Switch,
            payload: {
              description: block.blockDescription,
              displayNumber: block.displayNumber,
              label: block.inputHeading,
              options: block.options.map((option) => ({
                value: option.value,
                viewValue: option.label,
              })),
              hint: block.inputHint,
            },
          },
        });
      }
      case TaskInputType.DATETIME:
      case TaskInputType.DATE: {
        return of({
          data: {
            type: BlockType.Datepicker,
            payload: {
              description: block.blockDescription,
              displayNumber: block.displayNumber,
              label: block.inputHeading,
              hint: block.inputHint,
              type: block.inputType === TaskInputType.DATETIME ? 'datetime' : 'date',
            },
          },
          resultHandler: (result: DatepickerBlockResult) => {
            const date = new Date(result.date.getTime());

            if (block.inputType === TaskInputType.DATETIME) {
              const [hour, minutes] = convertTimeString(result.time);

              date.setHours(hour);
              date.setMinutes(minutes);
            }
            return of(getISODateStringWithCurrentTimezone(date));
          },
        });
      }
      case TaskInputType.NUMBER: {
        return of({
          data: {
            type: BlockType.Input,
            payload: {
              description: block.blockDescription,
              displayNumber: block.displayNumber,
              label: block.inputHeading,
              type: InputType.Numeric,
              hint: block.inputHint,
            },
          },
        });
      }
      case TaskInputType.EMAIL: {
        return of({
          data: {
            type: BlockType.Input,
            payload: {
              description: block.blockDescription,
              displayNumber: block.displayNumber,
              label: block.inputHeading,
              type: InputType.Email,
              hint: block.inputHint,
            },
          },
        });
      }
      case TaskInputType.TEXTAREA: {
        return of({
          data: {
            type: BlockType.Input,
            payload: {
              description: block.blockDescription,
              displayNumber: block.displayNumber,
              label: block.inputHeading,
              type: InputType.Textarea,
              hint: block.inputHint,
            },
          },
        });
      }
      case TaskInputType.SELECT: {
        return of({
          data: {
            type: block.multiSelect ? BlockType.MultiSelect : BlockType.Select,
            payload: {
              description: block.blockDescription,
              displayNumber: block.displayNumber,
              label: block.inputHeading,
              options:
                block.options?.map(({ value, label }) => ({
                  value,
                  viewValue: label,
                })) || [],
            },
          },
          resultHandler: (result: InputDataVM[] | InputDataVM | null) => {
            if (isNil(result) || (Array.isArray(result) && result.length === 0)) {
              // tslint:disable-next-line: no-null-keyword
              return null;
            }

            if (Array.isArray(result)) {
              return of(result.map((item) => item.value.toString()).join(';'));
            }

            return of(result.value.toString());
          },
        });
      }
    }
  }

  /**
   * Map Component type task block data
   *
   * @param block
   * @param taskDetails
   * @private
   */
  // @ts-ignore
  // eslint-disable-next-line sonarjs/cognitive-complexity
  private mapComponentBlock$(block: TaskTemplateBlocks, taskDetails: TaskDetails): Observable<MappedBlock> {
    switch (block.cmpName) {
      case TaskComponentName.ADD_PACKAGES: {
        return this.userDefaultsService.getUserDefaults$().pipe(
          map((defaults) => ({
            data: {
              type: BlockType.Packages,
              payload: {
                description: block.blockDescription,
                displayNumber: block.displayNumber,
                weightUnit: mapWeightUnitWithNull(defaults.ClientDefaultvalues[0].PackageWeightUnits),
                lengthUnit: mapLengthUnit(defaults.ClientDefaultvalues[0].PackageDimensions),
              },
            },
            resultHandler: (result: ChargeableWeightDialogResult) =>
              this.authService.getUser$().pipe(
                switchMap((user) =>
                  this.packageService.updateQuotePackages$(
                    {
                      lengthUnit: LengthUnit.Cm,
                      packages: [],
                      quoteId: taskDetails.SOInfo?.[0]?.ShipmentOrderID,
                      weightUnit: WeightUnit.Kg,
                    },
                    result,
                    user
                  )
                ),
                // eslint-disable-next-line unicorn/no-useless-undefined
                mapTo(undefined)
              ),
          }))
        );
      }
      case TaskComponentName.ADD_PICK_UP_ADDRESS: {
        const country =
          taskDetails.SOInfo?.[0]?.ServiceType === ServiceType.EOR
            ? taskDetails.SOInfo?.[0]?.Destination
            : taskDetails.SOInfo?.[0]?.ShipFromCountry;

        return this.addressService.getCachedPickupAddressesForCountry$(country, false).pipe(
          map((addresses) => ({
            data: {
              type: BlockType.PickUpAddress,
              payload: {
                description: block.blockDescription,
                displayNumber: block.displayNumber,
                country,
                addresses,
                onCreate: () =>
                  this.addressService
                    .createPickupAddressThroughDialog$([{ value: country, viewValue: country }], country)
                    .pipe(
                      switchMap((result) =>
                        this.addressService
                          .getCachedPickupAddressesForCountry$(country, true)
                          .pipe(map((newAddresses) => ({ addresses: newAddresses, result })))
                      )
                    ),
                onEdit: (address: AddressCardAddressVM) =>
                  this.addressService
                    .editAddressThroughDialog$(AddressType.Pickup, address, [{ value: country, viewValue: country }])
                    .pipe(switchMap(() => this.addressService.getCachedPickupAddressesForCountry$(country, true))),
              },
            },
            resultHandler: (selectedAddresses: AddressCardAddressVM[]) =>
              this.authService.getUser$().pipe(
                switchMap((user) =>
                  this.rePollService.updateFreightAddressRetryShipment$({
                    accessToken: user.accessToken,
                    FRID: taskDetails.freight?.Id,
                    pickupAddressId: selectedAddresses[0].id,
                  })
                ),
                // eslint-disable-next-line unicorn/no-useless-undefined
                mapTo(undefined)
              ),
          }))
        );
      }
      case TaskComponentName.ADD_FINAL_DELIVERY_ADDRESSES: {
        const country =
          taskDetails.SOInfo?.[0]?.ServiceType === ServiceType.EOR
            ? taskDetails.SOInfo?.[0]?.ShipFromCountry
            : taskDetails.SOInfo?.[0]?.Destination;

        return this.addressService.getCachedShipToAddressesForCountry$(country, false).pipe(
          map((addresses) => ({
            data: {
              type: BlockType.ShipToAddress,
              payload: {
                description: block.blockDescription,
                displayNumber: block.displayNumber,
                country,
                addresses,
                onCreate: () =>
                  this.addressService
                    .createShipToAddressThroughDialog$([{ value: country, viewValue: country }], country)
                    .pipe(
                      switchMap((result) =>
                        this.addressService
                          .getCachedShipToAddressesForCountry$(country, true)
                          .pipe(map((newAddresses) => ({ addresses: newAddresses, result })))
                      )
                    ),
                onEdit: (address: AddressCardAddressVM) =>
                  this.addressService
                    .editAddressThroughDialog$(AddressType.Delivery, address, [{ value: country, viewValue: country }])
                    .pipe(switchMap(() => this.addressService.getCachedShipToAddressesForCountry$(country, true))),
              },
            },
            resultHandler: (selectedAddresses: AddressCardAddressVM[]) =>
              this.authService.getUser$().pipe(
                switchMap((user) =>
                  this.rePollService.updateFinalDeliveryAddressesRetry$({
                    accessToken: user.accessToken,
                    shipmentOrderId: taskDetails.SOInfo?.[0]?.ShipmentOrderID,
                    finalDelivery: selectedAddresses.map((address) => ({
                      FinalDestinationAddressID: address.id,
                      Name: address.tag,
                    })),
                  })
                ),
                // eslint-disable-next-line unicorn/no-useless-undefined
                mapTo(undefined)
              ),
          }))
        );
      }
      case TaskComponentName.UPLOAD_DOCUMENT: {
        return of({
          data: {
            type: BlockType.Upload,
            payload: {
              displayNumber: block.displayNumber,
              text: block.blockDescription,
            },
          },
          resultHandler: (files: File | File[]) => {
            const normalizedFiles = Array.isArray(files) ? files : [files];

            return forkJoin(
              normalizedFiles.map((file) =>
                FileHelper.readFileAsBase64$(file).pipe(
                  map((body) => {
                    let name = file.name;
                    if (!isNil(block.documentAppendedText) && !name.includes(block.documentAppendedText)) {
                      name = `${block.documentAppendedText} - ${file.name}`;
                    }

                    return { name, body };
                  })
                )
              )
            ).pipe(
              withLatestFrom(this.authService.getUser$()),
              switchMap(([items, user]) =>
                forkJoin(
                  items.map((item) =>
                    this.documentsDataService.attachDocuments({
                      Accesstoken: user.accessToken,
                      RecordID: block.sourceId,
                      Atts: [
                        {
                          filename: item.name,
                          filebody: item.body,
                        },
                      ],
                    })
                  )
                )
              ),
              // eslint-disable-next-line unicorn/no-useless-undefined
              mapTo(undefined)
            );
          },
        });
      }
      case TaskComponentName.DOWNLOAD_DOCUMENT: {
        return of({
          data: {
            type: BlockType.Download,
            payload: {
              text: block.blockDescription,
              displayNumber: block.displayNumber,
              url: `${this.config.documentDownloadBaseUrl}${block.url}`,
              name: block.docName,
            },
          },
        });
      }
      case TaskComponentName.BATTERY_PI_NUMBERS_TABLE: {
        return this.authService.getUser$().pipe(
          switchMap((user) =>
            this.rePollService.pollSoRelatedObjects$({
              accessToken: user.accessToken,
              accountId: user.accountId,
              shipmentOrderId: taskDetails.SOInfo?.[0]?.ShipmentOrderID,
            })
          ),
          map((shipmentOrderRelations) => ({
            data: {
              type: BlockType.Battery,
              payload: {
                description: block.blockDescription,
                displayNumber: block.displayNumber,
                packages: mapShipmentOrderPackages(shipmentOrderRelations),
              },
            },
            resultHandler: (results: BatteryTableBlockResult) =>
              this.authService.getUser$().pipe(
                switchMap((user) =>
                  this.rePollService.updateSoPackageRetry$({
                    user,
                    payload: zip(results, shipmentOrderRelations.ShipmentOrderPackages).map(([result, shipmentOrderPackage]) =>
                      mapUpdatePackagePayload(result, shipmentOrderPackage)
                    ),
                  })
                ),
                // eslint-disable-next-line unicorn/no-useless-undefined
                mapTo(undefined)
              ),
          }))
        );
      }
      case TaskComponentName.PART_SUB_CATEGORY_TABLE: {
        return this.authService.getUser$().pipe(
          switchMap((user) =>
            this.rePollService.pollSoRelatedObjects$({
              accessToken: user.accessToken,
              accountId: user.accountId,
              shipmentOrderId: taskDetails.SOInfo?.[0].ShipmentOrderID,
            })
          ),
          map((shipmentOrderRelations) => {
            const lineItemsToAsk = shipmentOrderRelations.Parts.filter((part) => isNil(part.USHTSCode)).map((part) => ({
              id: part.Id,
              productCode: part.PartNumber,
              description: part.PartDescription,
              quantity: part.Quantity,
              unitPrice: part.UnitPrice,
            }));

            return {
              data: {
                type: BlockType.PartSubCategoryTable,
                payload: {
                  lineItems: lineItemsToAsk,
                },
              },
              resultHandler: (results: string[]) => {
                /**
                 * Couple of line above we created an array that contains all the line items without an HS code,
                 * to ask the missing information from the user. Now we construct an array that again contains all the line items,
                 * with the updated HS codes from the user. It's important to send them all in the original order because this updateParts
                 * endpoint replaces all existing line items with what we send.
                 */
                const newParts: UpdatePartsRequestParts[] = shipmentOrderRelations.Parts.map((part) => {
                  const resultIndex = lineItemsToAsk.findIndex((lineItem) => lineItem.id === part.Id);

                  let category = part.Category;
                  if (resultIndex !== -1) {
                    category = [NOT_PROVIDED_CATEGORY, OTHER_CATEGORY].includes(results[resultIndex]) ? undefined : results[resultIndex];
                  }

                  return {
                    PartNumber: part.PartNumber,
                    PartDescription: part.PartDescription,
                    Quantity: part.Quantity,
                    UnitPrice: part.UnitPrice,
                    category,
                  };
                });

                return this.authService.getUser$().pipe(
                  switchMap((user) =>
                    this.rePollService.updatePartsRetry$({
                      accesstoken: user.accessToken,
                      accountId: user.accountId,
                      parts: newParts,
                      shipmentOrderId: taskDetails.SOInfo?.[0].ShipmentOrderID,
                    })
                  ),
                  // eslint-disable-next-line unicorn/no-useless-undefined
                  mapTo(undefined)
                );
              },
            };
          })
        );
      }
      case TaskComponentName.CAPTURE_PICKUP_PREFERENCE: {
        return this.authService.getUser$().pipe(
          switchMap((user) =>
            this.rePollService.pollSoRelatedObjects$({
              accessToken: user.accessToken,
              accountId: user.accountId,
              shipmentOrderId: taskDetails.SOInfo?.[0].ShipmentOrderID,
            })
          ),
          map((shipmentOrderRelations) => {
            const [freightDetails] = shipmentOrderRelations.FreightDetails;
            return {
              data: {
                type: BlockType.PickupPreference,
                payload: {},
              },
              resultHandler: (result: PickupPreference) => {
                // eslint-disable-next-line unicorn/no-useless-undefined
                return this.addressService.updatePickupReference$(result, freightDetails).pipe(mapTo(undefined));
              },
            };
          })
        );
      }
      case TaskComponentName.ADD_REGISTRATION_ADDRESS: {
        const country = taskDetails.SOInfo?.[0]?.ShipFromCountry;
        return this.addressService.getCachedExporterAddressesForCountry$(country, false).pipe(
          map((addresses) => ({
            data: {
              type: BlockType.ExporterAddress,
              payload: {
                description: block.blockDescription,
                displayNumber: block.displayNumber,
                country,
                addresses,
                onCreate: () =>
                  this.addressService
                    .createExporterAddressThroughDialog$([{ value: country, viewValue: country }], country)
                    .pipe(
                      switchMap((result) =>
                        this.addressService
                          .getCachedExporterAddressesForCountry$(country, true)
                          .pipe(map((newAddresses) => ({ addresses: newAddresses, result })))
                      )
                    ),
                onEdit: (address: AddressCardAddressVM) =>
                  this.addressService
                    .editAddressThroughDialog$(AddressType.Exporter, address, [{ value: country, viewValue: country }])
                    .pipe(switchMap(() => this.addressService.getCachedExporterAddressesForCountry$(country, true))),
              },
            },
            resultHandler: (selectedAddresses: AddressCardAddressVM[]) =>
              this.authService.getUser$().pipe(
                switchMap((user) =>
                  this.rePollService.updateCRAddressToFreightRetry$({
                    accessToken: user.accessToken,
                    freightId: taskDetails.freight?.Id,
                    crAddressId: selectedAddresses[0].id,
                  })
                ),
                // eslint-disable-next-line unicorn/no-useless-undefined
                mapTo(undefined)
              ),
          }))
        );
      }
    }
  }

  /**
   * Handler to open a task's messages
   *
   * @param taskDetails
   * @param defaultTeamMember
   * @param taskType
   * @private
   */
  private createOnMsgClickHandler(
    { taskDetails, defaultTeamMember }: { taskDetails: TaskDetails; defaultTeamMember: MessageButtonUserVM },
    taskType: TaskType
  ): () => void {
    const shipmentOrder = mapTaskShipmentOrder(taskDetails);
    return () => {
      this.tasksMessageDialogService.open({
        messageType: MessageChannelType.Task,
        id: taskDetails.task.Id,
        ...([TaskType.PreInspection, TaskType.AirWaybill].includes(taskType) && { subject: taskDetails.template.taskTitle }),
        messageTo: defaultTeamMember,
        teamMemberListType: TeamMemberListType.ShipmentOrder,
        shipment: shipmentOrder && {
          id: shipmentOrder.id,
          reference: shipmentOrder.reference,
          title: shipmentOrder.name,
          parentName: taskDetails.task.Subject,
          master_task_id: taskDetails.task.Master_Task__c,
        },
        taskNameForAccountLevel: taskDetails.template.taskTitle,
        taggedRoles: [],
      });
    };
  }

  /**
   * Open a message deeplink
   *
   * @param openMessage
   * @private
   */
  private openMessageDeeplink(openMessage: () => void): void {
    if (window.location.hash.includes('message')) {
      openMessage();
    }
  }

  public mixpanelCloseTask(taskDetails: TaskDetails): void {
    const url = this.router.url;
    let screen_name_base = '';
    if (url.includes('dashboard')) {
      screen_name_base = '/dashboard#task';
    } else if (url.includes('shipments')) {
      screen_name_base = '/shipments-list/shipment-order';
    } else if (url.includes('new-quote')) {
      screen_name_base = '/new-quote/final-costs';
    }
    this.mixpanelService.track(MixpanelEvent.UserAction, {
      screen_name_base,
      button_name: 'Close task',
      task_title: taskDetails.template.taskTitle,
      master_task_id: taskDetails.task.Master_Task__c,
      client_assigned_to: taskDetails.task.Client_Assigned_To__c,
    });
  }

  public mixpanelSubmitTask(taskDetails: TaskDetails, updated_task: boolean): void {
    const url = this.router.url;
    let screen_name_base = '';
    if (url.includes('dashboard')) {
      screen_name_base = '/dashboard#task';
    } else if (url.includes('shipments')) {
      screen_name_base = '/shipments-list/shipment-order';
    } else if (url.includes('new-quote')) {
      screen_name_base = '/new-quote/final-costs';
    }

    this.mixpanelService.track(MixpanelEvent.UserAction, {
      screen_name_base,
      button_name: 'Submit',
      task_title: taskDetails.template.taskTitle,
      master_task_id: taskDetails.task.Master_Task__c,
      client_assigned_to: taskDetails.task.Client_Assigned_To__c,
      updated_task,
    });
  }
}
