<div class="dialog-container">
  <div class="dialog-content">
    <div class="d-flex justify-content-between">
      <h3 class="dialog-title mb-0" [innerHTML]="data.payload.title"></h3>
      <app-message-button [user]="data.payload.defaultTeamMember" (buttonClick)="onMessageClick()"></app-message-button>
    </div>

    <div class="part-table" [hidden]="isValid">{{ 'TASKS.SERIAL_NUMBER.COMPLETE_ALL' | translate }}</div>

    <form [formGroup]="partsForm" (ngSubmit)="onSubmitClick()">
      <div class="part-table">
        <table class="table">
          <thead>
            <tr>
              <th>{{ 'TASKS.SERIAL_NUMBER.NUMBER' | translate }}</th>
              <th>{{ 'TASKS.SERIAL_NUMBER.NAME' | translate }}</th>
              <th>{{ 'TASKS.SERIAL_NUMBER.SERIAL_NUMBER' | translate }}</th>
            </tr>
          </thead>
          <tbody>
            <tr formArrayName="parts" *ngFor="let part of parts.controls; let i = index">
              <td>
                {{ i + 1 }}
              </td>
              <td [formGroupName]="i">
                <input [readonly]="true" type="text" formControlName="name" />
              </td>
              <td [formGroupName]="i" [ngClass]="parts.controls[i].status === 'INVALID' ? 'invalid' : ''">
                <input [placeholder]="'TASKS.SERIAL_NUMBER.ADD_NUMBER' | translate" type="text" formControlName="serialNumber" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </form>
  </div>

  <div class="dialog-footer">
    <div class="cross"></div>
    <div class="d-flex align-items-center justify-content-end w-100">
      <button mat-button type="button" class="secondary-button mr-3" (click)="onCancelClick()">
        {{ 'COMMON.CANCEL' | translate }}
      </button>
      <div
        class="d-inline-block"
        [appTooltip]="data.payload.isInactive && 'TASKS.INACTIVE_TOOLTIP' | translate"
        [appTooltipStrategy]="'hover'"
      >
        <button mat-button class="primary-button" (click)="onSubmitClick()" [disabled]="!isValid">
          {{ 'COMMON.SUBMIT' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
